<template>
    <div>
        <div v-for="item in selectedValues" :key="item.id" class="flex">
            <div v-if="item.isVisible" class="pt-2 w-36 text-sm">{{ item.name }}</div>
            <div v-if="item.isVisible" class="pt-2 pb-3 w-full text-sm">
                <radio-button :entries="getRadioLabels(item)" :horizontal="true" @change="getSelectedValue(item.id, ...arguments)" :selected-id="item.value" />
            </div>
        </div>
    </div>
</template>
<script>
import RadioButtonList from "../../../../components/radioButtonList";
import Enum from "../../supplierInvoice.enum";
export default {
    props: ["VatRegistrationNo", "isInEU", "moms", "selected"],
    data() {
        return {
            tempVatRegistrationNumber: undefined,
            supplierCountry: [
                { value: Enum.supplierCountry.Sweden, name: "Sverige", disabled: false },
                { value: Enum.supplierCountry.Europe, name: "Eu", disabled: false },
                { value: Enum.supplierCountry.Foreign, name: "Utanför Eu", disabled: false },
                { value: Enum.supplierCountry.SwedenForeign, name: "Sverige/Utländskt (Ruta 23)", disabled: false },
            ],
            reverseConstruction: [
                { value: Enum.reverseConstruction.No, name: "Nej", disabled: false },
                { value: Enum.reverseConstruction.Yes, name: "Ja", disabled: false },
            ],
            momsNumber: [
                { value: Enum.momsNumber.Reported, name: "Angivet", disabled: false },
                { value: Enum.momsNumber.NotReported, name: "Ej angivet", disabled: false },
            ],
            vatRate: [
                { value: Enum.vatRate.TwentyFive, name: "25%", disabled: false },
                { value: Enum.vatRate.Twelve, name: "12%", disabled: false },
                { value: Enum.vatRate.Six, name: "6%", disabled: false },
                { value: Enum.vatRate.Zero, name: "0%", disabled: false },
                { value: Enum.vatRate.Mixed, name: "Blandad", disabled: false },
            ],
            selectedValues: [
                { id: 0, type: "supplierCountry", name: "Inköpsland", value: -1, isVisible: true },
                { id: 1, type: "reverseConstruction", name: "Omvänd Byggmoms", value: -1, isVisible: false },
                { id: 2, type: "momsNumber", name: "Momsnummer", value: -1, isVisible: false },
                { id: 3, type: "vatRate", name: "Moms", value: -1, isVisible: false },
            ],
        };
    },
    components: {
        "radio-button": RadioButtonList,
    },
    watch: {
        selectedValues: {
            deep: true,
            handler(value) {
                this.$emit("selectedValues", value);
            },
        },
    },
    async created() {
        this.init();
    },
    methods: {
        init() {
            if (this.selected) {
                if (this.selected.supplierCountry == Enum.supplierCountry.Sweden) {
                    this.setSwedenValues();
                } else {
                    this.setOutsideSwedenValues();
                }

                if (this.selected.supplierCountry == Enum.supplierCountry.Sweden) {
                    this.setReverseConstruction(this.selected.reverseConstruction);
                } else {
                    this.setMomsNumber(this.selected.momsNumber);
                }
                this.selectedValues[0].value = this.selected.supplierCountry;
                this.selectedValues[1].value = this.selected.reverseConstruction;
                this.selectedValues[2].value = this.selected.momsNumber;
                this.selectedValues[3].value = this.selected.vatRate;
            } else if (this.VatRegistrationNo) {
                this.tempVatRegistrationNumber = this.VatRegistrationNo.trim().substring(0, 2);
                if (this.tempVatRegistrationNumber.toUpperCase() == "SE" || !isNaN(this.tempVatRegistrationNumber)) {
                    this.setItemValue(Enum.supplierCountry.Sweden, 0);
                    this.setSwedenValues();
                } else {
                    if (this.isInEU != "-1") {
                        this.setOutsideSwedenValues();
                        if (this.moms == 0) {
                            this.selectedValues[2].value = Enum.momsNumber.Reported;
                        } else {
                            this.selectedValues[2].value = Enum.momsNumber.NotReported;
                        }
                        this.setMomsNumber(this.selectedValues[2].value);
                    }
                    if (this.tempVatRegistrationNumber.toUpperCase() != "SE" && this.isInEU == "True") {
                        this.selectedValues[0].value = Enum.supplierCountry.Europe;
                    } else if (this.tempVatRegistrationNumber.toUpperCase() != "SE" && this.isInEU == "False") {
                        this.selectedValues[0].value = Enum.supplierCountry.Foreign;
                    }
                }
            } else {
                this.setItemValue(Enum.supplierCountry.Sweden, 0);
                this.setSwedenValues();
            }
        },
        getSelectedValue(id, value) {
            switch (this.selectedValues[id].type) {
                case "supplierCountry":
                    this.resetValues();
                    this.resetVatDisable();
                    this.setSupplierCountry(value.value);
                    break;
                case "reverseConstruction": {
                    this.setReverseConstruction(value.value);
                    break;
                }
                case "momsNumber":
                    this.setMomsNumber(value.value);
                    break;
                case "vatRate":
                    this.setItemValue(value.value, 3);
                    break;
                default:
                    break;
            }
        },
        setItemValue(value, id) {
            this.selectedValues[id].value = value;
        },
        setMomsNumber(value) {
            this.setItemValue(value, 2);
            this.resetVatDisable();
            if (value == Enum.momsNumber.Reported) {
                this.selectedValues[3].value = -1;
                this.disabledVatValues();
            } else if (value == Enum.momsNumber.NotReported) {
                this.vatRate.forEach(vat => {
                    vat.value != Enum.vatRate.Zero ? (vat.disabled = true) : false;
                });
                this.selectedValues[3].value = Enum.vatRate.Zero;
            }
        },
        setReverseConstruction(value) {
            this.setItemValue(value, 1);
            this.resetVatDisable();
            if (value == Enum.reverseConstruction.Yes) {
                this.disabledVatValues();
            } else {
                this.selectedValues[3].isVisible = false;
            }
        },
        disabledVatValues() {
            this.selectedValues[3].isVisible = true;
            this.vatRate.forEach(vat => {
                vat.value == Enum.vatRate.Zero ? (vat.disabled = true) : false, vat.value == Enum.vatRate.Mixed ? (vat.disabled = true) : false;
            });
        },
        setSupplierCountry(value) {
            this.setItemValue(value, 0);

            switch (value) {
                case Enum.supplierCountry.Sweden:
                    this.setSwedenValues();
                    break;
                case Enum.supplierCountry.SwedenForeign:
                case Enum.supplierCountry.Foreign:
                case Enum.supplierCountry.Europe: {
                    this.setOutsideSwedenValues(value);
                    break;
                }
                default:
                    break;
            }
        },
        setOutsideSwedenValues() {
            this.selectedValues[0].isVisible = true;
            this.selectedValues[1].isVisible = false;
            this.selectedValues[2].isVisible = true;
            this.selectedValues[3].isVisible = true;
        },
        setSwedenValues() {
            this.selectedValues[0].isVisible = true;
            this.selectedValues[1].isVisible = true;
            this.selectedValues[1].value = Enum.reverseConstruction.No;
        },
        resetVatDisable() {
            this.vatRate.forEach(vat => {
                vat.disabled = false;
            });
        },
        resetValues() {
            this.selectedValues.forEach(item => {
                (item.isVisible = false), (item.value = -1);
            });
        },
        getRadioLabels(item) {
            switch (item.type) {
                case "supplierCountry":
                    return this.supplierCountry;
                case "reverseConstruction":
                    return this.reverseConstruction;
                case "momsNumber":
                    return this.momsNumber;
                case "vatRate":
                    return this.vatRate;
                default:
                    break;
            }
        },
    },
};
</script>
